import React from "react";
import "../styles/heading.scss";

const Heading = (props) => {
  const { Tag, text, bold } = props;

  return (
    <div className={`header-${Tag}-container`}>
      <Tag className={`header-${Tag}`}>
        {text}
        {bold ? <span className="bold">{bold}</span> : null}
      </Tag>
    </div>
  );
};

export default Heading;
